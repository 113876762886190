.header--menu {

    // mobile version

    @include media-breakpoint-down(lg) {

        display: none;
        opacity: 0;
        pointer-events: hidden;
        position: absolute;
        top: calc(var(--top-utility-bar--height) + var(--header--height));
        left: 0;
        width: 100%;
        padding: 60px 0;
        height: calc(var(--vpHeight) - (var(--top-utility-bar--height) + var(--header--height)));
        overflow-y: auto;
        background-color: white;
        will-change: opacity;

        &[aria-hidden="false"] {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            opacity: 0;
        }
        .mobile-menu-active & {
            opacity: 1;
            pointer-events: all;
        }
        .mobile-menu-fading & {
            transition: opacity 300ms linear;
        }

        nav {
            --theme--link--color: #{$link-color};
            --theme--link--color--hover: #{$link-hover-color};
            margin: 0 auto;
            width: calc(100vw - 60px);
            max-width: 400px;
            & > ul {
                display: block;
                width: 100%;
                margin: 0;
                padding: 0;
                list-style: none;
                @include media-breakpoint-up(md) {
                    max-width: 400px;
                }
        
                & > li {
                    display: block;
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    overflow: hidden;

                    & > a, & > button {
                        appearance: none;
                        display: block;
                        width: 100%;
                        margin: 0;
                        padding: 15px 0;
                        border: 0;
                        text-align: left;
                        font-family: $font-alt-heading;
                        text-transform: uppercase;
                        text-decoration: none;
                        font-size: 1.4rem;
                        line-height: 1.6rem;
                        background-color: transparent;
                        color: var(--theme--link--color);
                        @include non-retina-font-smoothing-antialiased;
                        &:hover, &:focus {
                            color: var(--theme--link--color--hover);
                        }
                    }
                }

                button {
                    position: relative;
        
                    &:before {
                        display: block;
                        content: '';
                        position: absolute;
                        top: 15px;
                        right: 0;
                        width: 18px;
                        height: 18px;
                        background-color: $gray-500;
                        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M15,7H9V1A1,1,0,0,0,7,1V7H1A1,1,0,0,0,1,9H7v6a1,1,0,0,0,2,0V9h6a1,1,0,0,0,0-2Z'/%3E%3C/svg%3E");
                        mask-size: 100%;
                        mask-repeat: no-repeat;
                        transform: scale(0.84);
                        will-change: transform;
                        transition: transform 300ms ease-in-out;
                    }

                    &:hover, &:focus {
                        &:before {
                            background-color: var(--theme--link--color--hover);
                        }
                    }
        
                    &[aria-expanded="true"] {
                        color: var(--theme--link--color--hover);
                        &:before {
                            transform: scale(1) rotate(135deg);
                        }
                    }
                }

                /* submenu level */
        
                .submenu {
                    --theme--link--color: #{$color--darkest-grey};
                    --theme--link--color--hover: #{$link-hover-color};
                    overflow: hidden;
                    max-height: 0;
                    transition: max-height 300ms ease-in-out;
                    .submenu--canonical-link {
                        margin: 0 0 20px;
                        a {
                            font-family: $font-family-base;
                            font-size: 20px;
                            line-height: 1.2;
                            svg {
                                font-size: 16px;
                            }
                        }
                    }
                    .submenu--content-area--section {
                        margin: 0;
                        padding: 0 0 20px;
                        h3 {
                            font-family: $font-alt-heading;
                            font-size: 16px;
                            color: $gray-500;
                            @include non-retina-font-smoothing-antialiased;
                        }
                        ul {
                            display: block;
                            width: 100%;
                            margin: 0;
                            padding: 0;
                            list-style: none;
                            li {
                                display: block;
                                width: 100%;
                                margin: 0 0 10px;
                                padding: 0;
                                list-style: none;
                                a, button {
                                    font-family: $font-family-base;
                                    font-size: 20px;
                                    line-height: 1.2;
                                    text-transform: none;
                                    padding-left: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    // desktop version

    @include media-breakpoint-up(lg) {
        display: block;
        flex: 1 1 auto;

        nav {
            & > ul {
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 100%;
                margin: 0;
                padding: 0;
                list-style: none;
        
                & > li {
                    display: inline-block;
                    margin: 0;
                    padding: 0;
                    overflow: hidden;
                    list-style: none;

                    & > a, & > button {
                        appearance: none;
                        display: block;
                        position: relative;
                        width: 100%;
                        margin: 0;
                        padding: 15px 2px;
                        border: 0;
                        text-align: left;
                        font-family: $font-alt-heading;
                        text-transform: uppercase;
                        text-decoration: none;
                        font-size: 16px;
                        line-height: 18px;
                        background-color: transparent;
                        color: var(--theme--link--color);
                        @include non-retina-font-smoothing-antialiased;
                        &:after {
                            display: block;
                            content: '';
                            position: absolute;
                            bottom: 12px;
                            left: 2px;
                            width: calc(100% - 4px);
                            height: 2px;
                            background-color: var(--theme--link--color--hover);
                            opacity: 0;
                            transform: scaleX(0);
                            transition: opacity 300ms linear, transform 300ms ease-in;
                        }
                        &:hover, &:not([aria-expanded="true"]):focus {
                            color: var(--theme--link--color--hover);
                            &:after {
                                opacity: 1;
                                transform: scaleX(1);
                                transition: opacity 100ms linear, transform 300ms ease-out;
                            }
                        }
                    }

                    .submenu {

                        --theme--link--color: #{$color--darkest-grey};
                        --theme--link--color--hover: #{$link-hover-color};

                        --padding-left: 30px;
                        --image-area-width: calc(var(--padding-left) + 144px);

                        display: none;
                        pointer-events: hidden;
                        position: absolute;
                        top: calc(var(--top-utility-bar--height) + var(--header--height));
                        left: 0;
                        width: 100%;
                        min-height: 200px;
                        max-height: calc(100vh - (var(--top-utility-bar--height) + var(--header--height)));
                        background-color: white;
                        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
                        opacity: 0;
                        will-change: opacity;

                        &[aria-hidden="false"] {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: center;
                            opacity: 0;
                        }
                        .desktop-menu-active & {
                            opacity: 1;
                            pointer-events: all;
                        }
                        .desktop-menu-fading & {
                            transition: opacity 200ms linear;
                        }

                        .submenu--content-area {
                            position: relative;
                            width: 100%;
                            padding: 60px 0;
                            padding-left: var(--padding-left);
                            padding-right:  var(--image-area-width);

                            .submenu--canonical-link {
                                margin: 0 0 30px;
                                a {
                                    font-family: $font-strong;
                                    font-size: 18px;
                                    svg {
                                        font-size: 16px;
                                    }
                                }
                            }

                            .submenu--content-area--sections {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                justify-content: flex-start;
                                align-items: stretch;
                                gap: 20px 30px;
                                width: 100%;

                                .submenu--content-area--section {
                                    flex: 0 1 auto;
                                    width: calc((100% / 3) - 60px);
                                    h3 {
                                        font-family: $font-alt-heading;
                                        font-size: 16px;
                                        color: $brand--red;
                                        @include non-retina-font-smoothing-antialiased;
                                    }

                                    ul {
                                        margin: 0;
                                        padding: 0;
                                        list-style: none;

                                        li {
                                            margin: 0 0 10px;
                                            padding: 0;
                                            list-style: none;

                                            a {
                                                font-size: 18px;
                                            }
                                        }

                                    }
                                    
                                }

                            }

                            .submenu--close {
                                position: absolute;
                                top: 60px;
                                right: var(--image-area-width);
                                color: $gray-500;
                                &:hover, &:focus {
                                    color: var(--theme--link--color--hover);
                                }
                            }

                        }

                        .submenu--image-area {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: var(--image-area-width);
                            height: 100%;
                            mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 87 500'%3E%3Cpolygon points='87 0 0 500 87 500 87 0'/%3E%3C/svg%3E");
                            mask-position:	left bottom;
                            mask-size: 		100% auto;
                            mask-repeat:	no-repeat;
                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        
                    }
                }
            }
        }
        .submenu--menu-indicator {
            position: fixed;
            top: calc(var(--top-utility-bar--height) + var(--header--height) - 15px);
            left: 0;
            pointer-events: none;
            width: 30px;
            height: 15px;
            overflow: hidden;
            &:before {
                display: block;
                content: '';
                width: 0;
                height: 0;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid white;
                opacity: 0;
                transform: translate3d(0,15px,0);
                transition: transform 300ms ease-in-out, opacity 200ms linear;
                will-change: transform;
            }
            .desktop-menu-active &:before {
                opacity: 1;
                transform: translate3d(0,0,0);
                transition: transform 300ms ease-in-out, opacity 0s linear;
                transition-delay: 200ms;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        nav > ul > li .submenu {
            --padding-left: calc((100% - 1116px) / 2);
            --image-area-width: calc(var(--padding-left) + 189px);
        }
    }
    @include media-breakpoint-up(xxl) {
        nav > ul > li .submenu {
            --padding-left: calc((100% - 1296px) / 2);
            --image-area-width: calc(var(--padding-left) + 234px);
        }
    }
}
