﻿// ********************
// **  Site colours  **
// ********************

$brand--dark-blue:      rgb(28,53,85);
$brand--red:            rgb(215, 40, 45);
$brand--white:          rgb(255,255,255);

$color--lightest-grey: 	rgb(233, 235, 236);
$color--lighter-grey: 	rgb(193,198,200);
$color--mid-grey: 	    rgb(132, 146, 156);
$color--dark-grey: 	    rgb(91,103,112);
$color--darkest-grey: 	rgb(45,41,38);

$color--bluish-grey: 	rgb(231,236,242);
$color--blue: 	        rgb(11,85,175);

$green:                 rgb(19, 184, 0);

// set fundamental bootstrap colour variables

$primary:                       $brand--red;
$secondary:                     $color--mid-grey;
$light:                         $color--lightest-grey;
$dark:                          $color--darkest-grey;

$body-bg:                       $brand--white;
$body-color:                    $dark;

$link-color:                    $primary;
$link-decoration:               none;
$link-hover-color:              $color--blue;
$link-hover-decoration:         underline;


// ***********************
// **  top utility bar  **
// ***********************

$top-utility-bar--height--mobile:                   40px;
$top-utility-bar--height--desktop:                  40px;


// *******************
// **  main header  **
// *******************

$header--height--mobile:                            75px;
$header--height--desktop:                           75px;

// *****************
// **  Hero area  **
// *****************

$hero--landscape--min-height:            400px;
$hero--mobile--min-height:               360px;
$hero--desktop--min-height:              400px;
$hero--desktop--max-height:              600px;

// ***********************
// **  img-loader-icon  **
// ***********************

$img-loader-icon--width: 3rem;
$img-loader-icon--height: 3rem;
$img-loader-icon--background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.022 14'%3E%3Cg id='bullet_shape' data-name='bullet shape' transform='translate(0 0)'%3E%3Cpath id='Path_48' data-name='Path 48' d='M11.054,32.952a10.872,10.872,0,0,0,1.492-.131l.332-.049.283-.049.447-.087h.049V32.6c.033-.136.065-.294.093-.463l.1-.632c.06-.447.1-.948.131-1.492a14.126,14.126,0,0,0-.033-1.721c-.027-.278-.06-.545-.1-.822s-.076-.436-.12-.648a1.593,1.593,0,0,0-.06-.321c-.022-.1-.06-.207-.093-.31a10.526,10.526,0,0,0-.468-1.416,9.737,9.737,0,0,0-.457-.926c-.12-.212-.245-.43-.381-.632s-.31-.441-.479-.653a8.561,8.561,0,0,0-.664-.746,9.53,9.53,0,0,0-1.34-1.122.806.806,0,0,1-.114-.071c-.212-.136-.425-.267-.653-.392s-.419-.212-.637-.31l-.174-.082a8.093,8.093,0,0,0-.866-.316c-.152-.049-.289-.1-.43-.131l-.131-.038-.31-.076a13.07,13.07,0,0,0-1.8-.3,14.126,14.126,0,0,0-1.721,0,14,14,0,0,0-1.5.136l-.6.1a1.739,1.739,0,0,0-.457.093H.336a.169.169,0,0,1,0,.044c-.033.158-.06.3-.087.457l-.109.615a14.017,14.017,0,0,0-.152,1.492A14.159,14.159,0,0,0,.015,23.64a13.293,13.293,0,0,0,.278,1.786c.027.131.06.234.087.343l.027.1c.038.158.087.294.131.43.1.316.212.61.316.855a9.541,9.541,0,0,0,.871,1.568,9.323,9.323,0,0,0,4.1,3.355,11.148,11.148,0,0,0,1.732.545c.381.087.784.152,1.193.207.2.027.392.054.594.071h0l.632.033h1.056' transform='translate(0.027 -18.952)' fill='%23ffffff'/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat;

// **********************
// **  custom buttons  **
// **********************

$btn-theme--padding-x: 0.75rem;
$btn-theme--padding-top: 0.65rem;
$btn-theme--padding-bottom: 0.65rem;
$btn-theme--lg--padding-x: 1rem;
$btn-theme--lg--padding-top: 1rem;
$btn-theme--lg--padding-bottom: 1rem;
$btn-theme--sm--padding-x: 0.75rem;
$btn-theme--sm--padding-top: 0.5rem;
$btn-theme--sm--padding-bottom: 0.5rem;

// ***********************
// **  Image galleries  **
// ***********************

$image-gallery--small-thumb-height: 120px;
$image-gallery--medium-thumb-height: 180px;
$image-gallery--large-thumb-height: 240px;
$image-gallery--thumb--margin: 5px;
$image-gallery--thumb--bg-color: $color--darkest-grey;

$masonry-gallery--item-padding: 2px;

// **************************
// **  Responsive Nav Bar  **
// **************************

$nav-bar--bg-color: white;
$nav-bar--item--color: $color--dark-grey;
$nav-bar--item--decoration: none;
$nav-bar--item--hover-bg-color: white;
$nav-bar--item--hover-color: $link-hover-color;
$nav-bar--item--hover-decoration: underline;
$nav-bar--item--active-color: $primary;
$nav-bar--item--active-bg-color: $color--lightest-grey;

// **************
// **  Panels  **
// **************

$panel-flash-bg-colors: (
    red: $brand--red,
    black: rgb(0,0,0),
    pink: rgb(233, 4, 141),
    blue: $color--blue,
    green: $green
);



// ******************************************************************
// **                                                              **
// **   BOOTSTRAP VARIABLES - (overriding bootstrap defaults)      **
// **                                                              **
// ******************************************************************

$component-active-bg:           $primary;
$component-active-color:        white !important;

// Fonts

$font-family-sans-serif:       'Flama Book', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$font-strong:                  'Flama Bold', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$font-family-base:             $font-family-sans-serif;

$font-heading:                'United Italic Semi Condensed Medium', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$font-heading-strong:         'United Italic Semi Condensed Black', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$font-alt-heading:            'United Italic Regular Black', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

$font-size-base:              1.125rem; // Assumes the browser default, typically `16px`
$font-weight-base:            400;
$line-height-base:            1.4;

$lead-font-size:              $font-size-base * 1.05;
$lead-font-weight:            400;

$paragraph-margin-bottom:     1.25rem;

$headings-font-family:        $font-heading;
$headings-font-weight:        400;
$headings-line-height:        1.1;

$h1-font-size:                $font-size-base * 2.6;
$h2-font-size:                $font-size-base * 2.0;
$h3-font-size:                $font-size-base * 1.7;
$h4-font-size:                $font-size-base * 1.4;
$h5-font-size:                $font-size-base * 1.1;
$h6-font-size:                1rem;

$display1-size:               4rem;
$display2-size:               3.6rem;
$display3-size:               3.2rem;
$display4-size:               2.8rem;

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 3,
        ),
        // added
        6: (
            $spacer * 4,
        ),
        // added
        7:
            (
                $spacer * 5,
            ),
        // added
        8:
            (
                $spacer * 6,
            )
            // added,
    ),
    $spacers
);

// Forms + Buttons

$border-radius:                   0;
$border-radius-lg:                0;
$border-radius-sm:                0;

$btn-border-radius:           5px;
$btn-border-radius-sm:        $btn-border-radius;
$btn-border-radius-lg:        $btn-border-radius;

$form-select-border-radius:       $btn-border-radius;
$form-select-border-radius-sm:    $btn-border-radius;
$form-select-border-radius-lg:    $btn-border-radius;

$form-check-input-checked-color:        white;

$form-range-thumb-width:         1.4rem;
$form-range-thumb-height:        $form-range-thumb-width;
$form-range-thumb-active-bg:     $link-hover-color;
