:root {

    --vpHeight: 100vh;
    
    // ***********************************
    // *****  theming css variables  *****
    // ***********************************

    // basic
    --theme--background-color: white;
    background: var(--theme--background-color);
    @include on-light-theme-vars;
    
    // ******************************
    // *****  layout variables  *****
    // ******************************

    --header--height: #{$header--height--mobile};
    --top-utility-bar--height: #{$top-utility-bar--height--mobile};
    --promo-bar--height: 0px;

    --featured-link--line-offset: 2px;

    --panel-flash--bg-color: #{map-get($panel-flash-bg-colors,red)};

    @include media-breakpoint-up(lg) {
        --header--height: #{$header--height--desktop};
        --top-utility-bar--height: #{$top-utility-bar--height--desktop};
    }
}

// focus - modern browsers support for focus by different input methods - older browsers ignore entire rule
@supports(a:focus-visible) {
    :focus:not(:focus-visible) {
        outline: 0;
    }
    .btn:focus:not(:focus-visible) {
        box-shadow: none;
    }
}
@supports not (a:focus-visible) {
    body:not(.keyboard-control) {
        a:focus, .tab-pane:focus, .nav-link:focus {
            outline: 0;
        }
        .btn:focus {
            box-shadow: none;
        }
    }
}

body.site-layout {

    overscroll-behavior-y: none;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: calc(var(--top-utility-bar--height) + var(--header--height));

    #main-content {
        flex: 1 1 auto;
        [id] {
            scroll-margin-top: calc(var(--top-utility-bar--height) + var(--header--height) + 60px);
        }
    }

    #main-footer {
        flex: 0 0 auto;
        [id] {
            scroll-margin-top: calc(var(--top-utility-bar--height) + var(--header--height) + 60px);
        }
    }

    background: var(--theme--background-color);
    color: var(--theme--color);

    .page-edge-spacing {
        padding-left: var(--page-edge-spacing);
        padding-right: var(--page-edge-spacing);
    }

}

// Link colours

a:not(.btn, .dropdown-item, .page-link, .visual-listing--item), .btn.btn-link {
    color: var(--theme--link--color);
    text-decoration: var(--theme--link--text-decoration--color);
    &:hover, &:focus {
        color: var(--theme--link--color--hover);
        text-decoration: var(--theme--link--text-decoration--hover);
    }
}

// last child - no bottom margin

.last-child-mb-0 > *:last-child {
    margin-bottom: 0;
}

// rules

hr {
    border-color: var(--theme--rule--color);
}

// emergency message

.emergency-message {
    position: fixed;
    top: calc(var(--top-utility-bar--height) + var(--header--height));
    left: 0;
    width: 100%;
    z-index: 9;
    border: 0;
    max-height: calc(90vh - (var(--top-utility-bar--height) + var(--header--height)));
    overflow-y: auto;
    transition: transform 300ms ease-in-out;
    will-change: transform;
    .fa-exclamation-triangle {
        color: var(--theme--decor--color);
    }
    .header-hidden:not(.mobile-menu-active) & {
        transform: translate3d(0,calc(-1 * (var(--top-utility-bar--height) + var(--header--height) + var(--promo-bar--height))),0);
    }
    .close {
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

// promo bar

body.with-promo-bar {
    --promo-bar--height: 40px;
    --promo-bar--bg-color: #{$color--dark-grey};
    .promo-bar {
        position: fixed;
        top: calc(var(--top-utility-bar--height) + var(--header--height));
        left: 0;
        width: 100%;
        height: var(--promo-bar--height);
        z-index: 8;
        border: 0;
        background-color: var(--promo-bar--bg-color);
        will-change: transform;
        transition: transform 300ms ease-in-out, background-color 300ms linear;
        & > div {
            font-size: 1rem;
            height: 100%;
            ul {
                height: 100%;
            }
        }
    }
    &.header-hidden:not(.mobile-menu-active) .promo-bar {
        transform: translate3d(0,calc(-1 * (var(--top-utility-bar--height) + var(--header--height) + var(--promo-bar--height))),0);
    }
    &.with-hero {
        --promo-bar--bg-color: #{rgba($color--dark-grey, 0.8)};
        --promo-bar--scrolled--bg-color: #{$color--dark-grey};
        .promo-bar {
            backdrop-filter: blur(10px);
        }
        &.page-scrolled .promo-bar {
            background-color: var(--promo-bar--scrolled--bg-color);
            transition-delay: 0s, 300ms;
        }
    }
}

// page-title-area

.page-title-area {
    margin-top: 2rem;
    margin-bottom: 0;
    .page-title-area--inner {
        .breadcrumb {
            background-color: transparent;
            font-size: 1rem;
            justify-content: center;
            margin-bottom: 0.25rem;
            .breadcrumb-item {
                &:not(:first-child):before {
                    content: '';
                    display: inline-block;
                    vertical-align: baseline;
                    height: 100%;
                    width: 1em;
                    background: no-repeat center url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512' class='svg-inline--fa fa-angle-right fa-w-6'%3E%3Cpath fill='%23c1c6c8' d='M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z' class=''%3E%3C/path%3E%3C/svg%3E");
                    background-size: auto 80%;
                    background-position: bottom left;
                }
                a {
                    &:hover, &:focus {
                        text-decoration: underline;
                    }
                }
            }
        }
        h1 {
            font-family: $font-alt-heading;
            margin-top: 1rem;
            margin-bottom: 0;
            text-align: center;
        }
    }

    // hero overlap

    .hero + & {
        position: relative;
        margin-top: -1.5rem;
        margin-bottom: 1rem;
        z-index: 2;
        .page-title-area--inner {
            position: relative;
            background-color: white;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            nav {
                display: flex;
                position: absolute;
                bottom: 100%;
                left: 0;
                width: 100%;
                justify-content: center;
                .breadcrumb {
                    display: inline-flex;
                    margin: 0 auto;
                    background-color: rgba(black, 0.4);
                    backdrop-filter: blur(10px);
                    padding: 0.3rem 1rem;
                    .breadcrumb-item {
                        a {
                            color: $color--lightest-grey;
                            text-decoration: none;
                            &:hover, &:focus {
                                color: white;
                                text-decoration: underline;
                            }
                        }
                        &:not(:first-child):before {
                            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512' class='svg-inline--fa fa-angle-right fa-w-6'%3E%3Cpath fill='%23d7282d' d='M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z' class=''%3E%3C/path%3E%3C/svg%3E");
                        }
                    }
                }
            }
            h1 {
                padding-top: 0.5rem;
                margin: 0;
                transform: translateY(0.25em);
            }
        }
        @include media-breakpoint-up(md) {
            margin-top: 0;
            height: 0;
            & > div {
                transform: translateY(calc(-1 * (100% - 1px)));
                .page-title-area--inner {
                    nav {
                        .breadcrumb {
                            padding: 0.5rem 1.5rem;
                        }
                    }
                    h1 {
                        padding-top: 1rem;
                    }
                }
            }
        }
    }
}

// full-width--breakout (make content that has to be inside a column but needs to be full width)

.full-width-breakout {
    display: block;
    width: 100%;
    &--inner {
        display: block;
        position: relative;
        width: 100vw;
        margin: 0 0 0 calc((50vw - 50%) * -1) !important;
    }
}


/* 50/50 layout with optional carousel */

.split-img-content-layout {
    display: flex;
    flex-direction: column;
    .split-img-content-layout--img {
        background-color: $color--darkest-grey;
        width: 100%;
    }
    .split-img-content-layout--content {
        width: 100%;
    }
    @include media-breakpoint-up(lg) {
        --center-content-margin: 30px;
        flex-direction: row;
        align-items: stretch;
        .split-img-content-layout--img {
            position: relative;
            width: 50%;
            min-height: 100%;
            &:before { /* used to set the minimum section height to 4:3 image height */
                display: block;
                content: '';
                width: 100%;
                height: 0;
                padding-bottom: 75%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                font-family: 'object-fit: cover';
            }
            .carousel {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                .carousel-inner, .carousel-item {
                    height: 100%;
                }
            }
        }
        .split-img-content-layout--content {
            display: flex;
            width: 50%;
            min-height: 100%;
            align-items: center;
            .split-img-content-layout--content--inner {
                width: 100%;
                .container { // set max-widths to align with other page elements
                    margin-left: var(--center-content-margin);
                    margin-right: auto;
                    max-width: calc((#{map-get($container-max-widths, lg)} / 2) - var(--center-content-margin));
                    @include media-breakpoint-up(xl) {
                        max-width: calc((#{map-get($container-max-widths, xl)} / 2) - var(--center-content-margin));
                    }
                    @include media-breakpoint-up(xxl) {
                        max-width: calc((#{map-get($container-max-widths, xxl)} / 2) - var(--center-content-margin));
                    }
                }
            }
        }

        &.split-img-content-layout--img-right {
            flex-direction: row-reverse;
            .split-img-content-layout--content {
                .container { // set max-widths to align with other page elements
                    margin-left: auto;
                    margin-right: var(--center-content-margin);
                }
            }
        }
    }
}

// 50/50 layout

.section-50-50 {
    .section-50-50--content > .container-fluid > .row {
        justify-content: center;
    }
    @include media-breakpoint-up(lg) {
        display: flex;
        .section-50-50--img {
			flex: 1;
            position: relative;
            width: 50%;
            &:before { /* used to set the minimum section height to square image height */
                display: block;
                content: '';
                width: 100%;
                height: 0;
                padding-bottom: 56.25%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                font-family: 'object-fit: cover';
            }
            .carousel {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                .carousel-inner, .carousel-item {
                    height: 100%;
                }
            }
        }
        .section-50-50--content {
            display: flex;
			align-items: center;
			min-width: 100%;
			margin-left: calc(0px - 50%);
			flex: 1;
            & > .container-lg > .row {
                justify-content: flex-end;
            }
        }
        &.section-50-50--img-right {
            flex-direction: row-reverse;
            .section-50-50--content {
                margin-left: 0;
                margin-right: calc(0px - 50%);
                flex-direction: row;
                & > .container-lg > .row {
                    justify-content: flex-start;
                }
            }
        }
    }
}

// 100vh bg image section

.full-screen-image-bg-section {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: $color--darkest-grey;
    &--img {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        user-select: none;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &--content {
        position: relative;
        z-index: 2;
        opacity: 0;
        transform: translate3d(0,30px,0);
        will-change: transform;
        transition: opacity 500ms linear, transform 1s ease-out;
        transition-delay: 500ms;
    }
    &.content-align-top {
        align-items: flex-start;
    }
    &.content-align-bottom {
        align-items: flex-end;
    }
    &.hunt-in .full-screen-image-bg-section--content {
        opacity: 1;
        transform: translate3d(0,0,0);
    }
}

// Widescreen img - content panel overlap -->

.widecreen-image-panel-overlap {
    --img-width: 640px;
    --content-width: 376px;
    &--img {
        background-color: $color--darkest-grey;
        img {
            aspect-ratio: 16/9;
        }
    }
    @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        min-height: calc(var(--img-width) * .5625);
        padding: 48px 0;
        &--img {
            display: block;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: var(--img-width);
            height: 100%;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &--content {
            position: relative;
            z-index: 2;
            width: var(--content-width);
        }
        &.widecreen-image-panel-overlap--img-right {
            justify-content: flex-start;
            .widecreen-image-panel-overlap--img {
                left: auto;
                right: 0;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        --img-width: 736px;
        --content-width: 451px;
    }
    @include media-breakpoint-up(xxl) {
        --img-width: 856px;
        --content-width: 526px;
    }
}

// snow overlay

@keyframes snow-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
#snow-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 9000;
    will-change: opacity;
    animation: snow-fade-in 1s linear;
    animation-fill-mode: forwards;
    transition: opacity 1s linear;
}
