// Booking stages indicator

.booking-stages {
    --stages--bg-color: #{$color--lightest-grey};
    --stages--color: #{$color--dark-grey};
    --stages--label-color: #{$color--mid-grey};
    --stages--bg-color--active: #{$brand--dark-blue};
    --stages--color--active: white;
    --stages--bg-color--complete: #{$green};
    ol {
        counter-reset: stage-counter;
        display: flex;
        position: relative;
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        &:before {
            display: block;
            content: "";
            position: absolute;
            z-index: 1;
            top: 14px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--stages--bg-color);
        }
        li {
            counter-increment: stage-counter;
            display: block;
            flex: 1 1 auto;
            position: relative;
            z-index: 2;
            margin: 0;
            padding: 0;
            text-align: center;
            .booking-stages--item {
                display: block;
                width: 100%;
                color: $body-color;
                font-family: $font-strong;
                font-size: 10px;
                line-height: 10px;
                text-transform: uppercase;
                color: var(--stages--label-color);
                &:before {
                    display: flex;
                    margin: 0 auto 8px;
                    padding-top: 0.1em;
                    justify-content: center;
                    align-items: center;
                    content: counter(stage-counter);
                    width: 30px;
                    height: 30px;
                    border-radius: 15px;
                    font-family: $font-alt-heading;
                    font-size: 14px;
                    line-height: 14px;
                    background-color: var(--stages--bg-color);
                    color: var(--stages--color);
                }
                &.complete:before {
                    content: '';
                    background: var(--stages--bg-color--complete) url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E") no-repeat;
                    background-size: 50%;
                    background-position: center;
                }
                &.active:before {
                    background-color: var(--stages--bg-color--active);
                    --color: var(--stages--color--active);
                    color: var(--stages--color--active);
                }
            }
            a.booking-stages--item {
                color: var(--stages--color);
                text-decoration: none;
                &:hover,
                &:focus {
                    color: var(--stages--color);
                    text-decoration: none;
                    &:before {
                        color: var(--stages--color);
                        text-decoration: none !important;
                    }
                    .label {
                        text-decoration: underline;
                    }
                }
            }
        }

        @media(max-width: 500px) {
            .booking-stages--item:not(.active) .label {
                display: none;
            }
        }

        @include media-breakpoint-up(md) {
            &:before {
                top: 19px;
            }
            li {
                width: calc(100% / 6);
                .booking-stages--item {
                    font-size: 12px;
                    line-height: 12px;
                    &:before {
                        width: 40px;
                        height: 40px;
                        border-radius: 20px;
                        font-size: 16px;
                        line-height: 16px;
                    }
                    &.complete:before {
                        background-size: 40%;
                    }
                }
            }
        }
    }
}

// booking-packages-review

.booking-packages-review {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 0;

    .quote {
        font-size: 1.2rem;
        text-wrap: balance;
        color: $brand--dark-blue;
        margin-bottom: 0;
    }

    .star-rating {
        font-size: 1rem;
        line-height: 1;
        color: $brand--red;
        margin-bottom: 0;

        & > span:first-child {
            display: flex;
            width: 100%;
            justify-content: center;
            gap: .2em;
        }

        svg {
            display: inline-block;
            height: 1em;
            width: 1.0526em;
        }
    }

    cite {
        display: flex;
        justify-content: center;
        gap: .5em;

        strong {
            font-family: $font-family-base;
            font-weight: $font-weight-base;
            font-size: 1rem;
        }
    }
}
.booking-packages-review-carousel {
    position: relative;
    padding: 0 40px;
    --swiper-pagination-bullet-width: 10px;
    --swiper-pagination-bullet-height: 10px;
    --swiper-pagination-bullet-horizontal-gap: 5px;
    --swiper-pagination-bullet-inactive-color: #{$color--lighter-grey};
    --swiper-pagination-bullet-inactive-opacity: 1;

    .swiper {
        padding-bottom: 50px;
    }

    .swiper-prev-btn, .swiper-next-btn {
        position: absolute;
        top: 50px;
        transform: translateY(-50%);
        z-index: 2;
    }

    .swiper-prev-btn {
        left: 0;
    }

    .swiper-next-btn {
        right: 0;
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            border-radius: 5px;

            &:hover, &:focus {
                background-color: var(--theme--link--color--hover);
                opacity: 1;
            }

            &.swiper-pagination-bullet-active {
                background-color: var(--theme--color--emphasis);

                &:hover, &:focus {
                    background-color: var(--theme--color--emphasis);
                }
            }
        }
    }
    // fix for duplicates and lazysizes
    .swiper-slide-duplicate img.lazy-fade-in,
    .swiper-slide-duplicate img.lazyloading {
        opacity: 1 !important;
    }

    .swiper-slide-duplicate img.lazy-scale-fade-in {
        opacity: 1 !important;
        transform: scale(1) !important;
    }
}

// package panel elements

.panel.package--panel {
    opacity: 1;
    transition: opacity 200ms linear;
    will-change: opacity;

    &.fade {
        opacity: 0.4;
    }

    .package--top-flash {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: translateY(-100%);
        background-color: var(--panel-flash--bg-color);
        color: white;
        font-size: 0.8rem;
        text-transform: uppercase;
        box-shadow: 0 2px 6px rgba(black, 0.3);
    }
    .package--pricing {
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .package--price {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;
        text-align: center;

        small {
            font-size: 0.8rem;
            color: var(--theme--color--subtle);
        }

        strong {
            font-family: $font-alt-heading;
            font-size: 1.6rem;
            transform: translateY(.075em);
        }

        &.old-price strong {
            position: relative;
            color: var(--theme--color--subtle);

            &:after {
                display: block;
                content: '';
                position: absolute;
                z-index: 2;
                top: 50%;
                left: 0;
                width: 100%;
                height: 3px;
                background-color: $brand--red;
                transform: rotate(-8deg) scaleX(1.1) translateY(-.05em);
            }
        }

        &.sale-price small {
            color: $brand--red;
        }
    }

    .package--image {
        max-width: 100%;
        aspect-ratio: 16/8.9;
    }

    .package--strapline {
        background-color: $brand--dark-blue;
        color: white;
        font-size: 0.8rem;
        text-transform: uppercase;
    }

    .package--info {
        font-size: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 10px;

        li {
            display: block;
            margin: 0;
            padding: 0;
        }
    }

    .package--summary {
        font-size: 1rem;
    }

    .package--feature-list {
        font-size: 1rem;
    }

    .package--controls {
        background-color: $color--lightest-grey;
        border-radius: $btn-border-radius;

        input[type="range"].form-range.form-number-control--range-slider {
            &::-webkit-slider-runnable-track {
                background-color: white;
            }

            &::-moz-range-track {
                background-color: white;
            }

            &::-webkit-slider-thumb:hover {
                background-color: $link-hover-color;
            }

            &::-moz-range-thumb:hover {
                background-color: $link-hover-color;
            }
        }
    }
    // voucher panels additions

    &.voucher--panel {

        .voucher-types--hm {
            display: flex;
            width: 100%;
            align-items: flex-end;
            margin-bottom: -$btn-border-radius !important;

            .voucher-types {
                display: flex;
                width: 100%;
                flex-direction: column;
                gap: 4px;
                font-size: 1rem;
                background-color: $color--lightest-grey;
                border-radius: $btn-border-radius $btn-border-radius 0 0;
                margin-bottom: 0 !important;

                .voucher-types--option {
                    display: flex;
                    width: 100%;
                    background-color: rgba(white, 0.5);
                    border-radius: $btn-border-radius;
                    padding: 5px 10px;
                    flex: 1 0 auto;
                    justify-content: center;

                    .form-check {
                        white-space: nowrap;
                        margin: 0;
                    }
                }
            }
        }

        .voucher-types--option--info {
            width: 100%;
            font-size: 1rem;

            p {
                margin-bottom: 8px;
            }

            & > *:last-child {
                margin-bottom: 0;
                padding-bottom: 15px;
            }
        }

        &.cash-voucher--panel {

            h3 {
                background-color: $brand--red;
                color: white;
                text-align: center;
                padding: 0.25rem 1rem;
            }

            .form-number-control {
                strong {
                    user-select: none;
                }
            }

            @include media-breakpoint-up(md) {
                margin-left: auto;
                margin-right: auto;
                max-width: 856px;
            }
        }
    }
}

// date picker

.monthly-date-picker {
    --max-scroll-height: min(75vh, 400px);
    --day--bg-color: white; // these are the 'unavailable' settings
    --day--day--color: #{$color--lighter-grey};
    --day--date--color: #{$color--lighter-grey};
    --day--padding: 10px;

    .monthly-date-picker--controls {
        --theme--link--color: #{$color--lightest-grey};
        --theme--link--text-decoration: none;
        --theme--link--color--hover: white;
        --theme--link--text-decoration--hover: none;
        display: flex;
        width: 100%;
        justify-content: space-between;
        background-color: $brand--dark-blue;
        color: white;
        .btn {
            flex: 0 0 auto;
        }
        .dropdown-menu {
            flex: 1 1 auto;
        }
    }
    .days-of-week,
    .monthly-date-picker--month {
        --transition-time: 300ms;
        @include list-unstyled;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        gap: 1px;
        li {
            display: flex;
            margin: 0;
            width: 100%;
            .monthly-date-picker--day {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                flex: 1 1 auto;
                padding: var(--day--padding);
                background-color: var(--day--bg-color);
                transition: background-color var(--transition-time) linear;
                cursor: not-allowed;
                .weekday, .day, .month-and-year {
                    transition: color var(--transition-time) linear;
                }
                .weekday {
                    color: var(--day--day--color);
                }
                .day {
                    color: var(--day--date--color);
                    font-size: 1.4rem;
                    font-family: $headings-font-family;
                    font-weight: $headings-font-weight;
                    line-height: $headings-line-height;
                    transform: translateY(0.1em);
                }
            }
            label.monthly-date-picker--day {
                cursor: pointer;
                --day--bg-color: white;
                --day--day--color: #{$brand--red};
                --day--date--color: $dark;
            }
            input[type="radio"]:not(:checked) + label.monthly-date-picker--day {
                &:hover, &:focus {
                    --transition-time: 100ms;
                    --day--bg-color: #{$color--blue};
                    --day--day--color: #{rgba(white,0.7)};
                    --day--date--color: white;
                }
            }
            input[type="radio"]:checked + label.monthly-date-picker--day {
                --transition-time: 100ms;
                --day--bg-color: #{$brand--red};
                --day--day--color: #{rgba(white,0.7)};
                --day--date--color: white;
            }
        }
    }
    &:not(.wide) {
        .days-of-week {
            display: none;
        }
        .monthly-date-picker--month {
            max-height: var(--max-scroll-height);
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar {
                appearance: none;
                width: 12px;
                background-color: rgba(black, 0.5);
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 6px;
                box-sizing: border-box;
                background-clip: content-box;
                border: 2px solid transparent;
                background-color: rgba(white, 0.5);
                cursor: pointer;
                &:hover,
                &:active {
                    background-color: white;
                }
            }
            li {
                &.past-date, &.other-month {
                    display: none;
                }
                .monthly-date-picker--day {
                    align-items: baseline;
                    .weekday {
                        order: 2;
                        margin-left: 10px;
                    }
                    .day {
                        order: 1;
                        font-size: 2rem;
                    }
                }
            }
        }
    }
    &.wide {
        --day--padding: 15px 2px;

        .days-of-week,
        .monthly-date-picker--month {
            li {
                width: calc(14%);
                flex: 1 1 auto;
                justify-content: center;
            }
            &:before, &:after {
                display: none;
            }
        }
        .days-of-week {
            margin-top: 1px;
            margin-bottom: 1px;
            li {
                background-color: white;
                color: $color--mid-grey;
                padding: 10px 2px;
                text-transform: uppercase;
                font-size: 0.8rem;
                line-height: 0.8rem;
                abbr {
                    text-decoration: none;
                    cursor: default;
                }
            }
        }
        .monthly-date-picker--month {
            .monthly-date-picker--day {
                justify-content: center;
                align-items: center;
                .weekday, .month-and-year { // visually-hidden
                    position: absolute !important;
                    width: 1px !important;
                    height: 1px !important;
                    padding: 0 !important;
                    margin: -1px !important;
                    overflow: hidden !important;
                    clip: rect(0, 0, 0, 0) !important;
                    white-space: nowrap !important;
                    border: 0 !important;
                }
                .day {
                    display: block;
                    width: 100%;
                    text-align: center;
                }
                &.today .day {
                    font-family: $font-heading-strong;
                }
            }
            li.other-month {
                display: flex;
                --day--bg-color: #{rgba(white, 0.3)}; // these are the 'unavailable' settings
                --day--date--color: #{$color--lighter-grey};
                visibility: hidden;
            }
        }
    }
}

// date picker

.timeslot-picker {
    --max-scroll-height: min(75vh, 400px);
    --h3--padding: 11px 10px 9px 20px;
    --slot--bg-color: white; // these are the 'unavailable' settings
    --slot--time--color: #{$color--lighter-grey};
    --slot--price--color: #{$color--lighter-grey};
    --slot--comment--color: #{rgba($brand--red, 0.5)};
    --slot--padding: 5px;

    h3 {
        display: block;
        width: 100%;
        background-color: $brand--dark-blue;
        color: $color--lightest-grey;
        padding: var(--h3--padding);
        font-size: 1rem;
        font-family: $font-alt-heading;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        text-transform: uppercase;
        margin-bottom: 1px;
    }

    .timeslot-picker--list {
        --transition-time: 300ms;
        @include list-unstyled;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        gap: 1px;
        li {
            display: flex;
            margin: 0;
            width: calc((100% - 1px) / 2);
            flex: 0 1 auto;
            align-items: stretch;
            min-height: 80px;
            .timeslot {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                min-height: 100%;
                flex: 1 1 auto;
                padding: var(--slot--padding);
                background-color: var(--slot--bg-color);
                transition: background-color var(--transition-time) linear;
                cursor: not-allowed;
                br {
                    display: none;
                }
                .time {
                    color: var(--slot--time--color);
                    font-size: 1.4rem;
                    font-family: $headings-font-family;
                    font-weight: $headings-font-weight;
                    line-height: $headings-line-height;
                    transform: translateY(0.1em);
                }
                .price {
                    color: var(--slot--price--color);
                }
                .comment {
                    color: var(--slot--comment--color);
                    font-size: 0.9rem;
                }
            }
            label.timeslot {
                cursor: pointer;
                --slot--bg-color: white;
                --slot--time--color: #{$dark};
                --slot--price--color: #{$color--dark-grey};
                --slot--comment--color: #{$brand--red};
            }
            input[type="radio"]:not(:checked) + label.timeslot {
                &:hover, &:focus {
                    --transition-time: 100ms;
                    --slot--bg-color: #{$color--blue};
                    --slot--time--color: white;
                    --slot--price--color: #{rgba(white,0.8)};
                    --slot--comment--color: #{rgba(white,0.8)};
                }
            }
            input[type="radio"]:checked + label.timeslot {
                --transition-time: 100ms;
                --slot--bg-color: #{$brand--red};
                --slot--time--color: white;
                --slot--price--color: #{rgba(white,0.8)};
                --slot--comment--color: #{rgba(white,0.8)};
            }
        }
        &:before, &:after {
            display: none;
        }
    }
    &:not(.wide):not(.wider) {
        position: relative;
        max-height: var(--max-scroll-height);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            appearance: none;
            width: 12px;
            background-color: rgba(black, 0.5);
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 6px;
            box-sizing: border-box;
            background-clip: content-box;
            border: 2px solid transparent;
            background-color: rgba(white, 0.5);
            cursor: pointer;
            &:hover,
            &:active {
                background-color: white;
            }
        }

        h3 {
            position: sticky;
            z-index: 2;
            top: 0;
        }
    }
    &.wide {
        .timeslot-picker--list {
            li {
                width: calc((100% - 3px) / 4);
            }
        }
    }
    &.wider {
        .timeslot-picker--list {
            li {
                width: calc((100% - 5px) / 6);
            }
        }
    }
}

// extras panel elements

.panel.extras--panel {
    .extras--top-flash {
        background-color: var(--panel-flash--bg-color);
        color: white;
        font-size: 0.8rem;
        text-transform: uppercase;
    }
    .extras--price {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;
        text-align: center;
        small {
            font-size: 0.8rem;
            color: var(--theme--color--subtle);
        }
        strong {
            font-family: $font-alt-heading;
            font-size: 1.6rem;
            transform: translateY(.075em);
        }
    }
    .extras--image {
        aspect-ratio: 16/8.9;
    }
    .extras--summary {
        font-size: 1rem;
    }
    .extras--more {
        font-size: 1rem;
        .collapse > *:last-child {
            margin-bottom: 0;
        }
    }
    .extras--controls {
        background-color: $color--lightest-grey;
        border-radius: $btn-border-radius;
    }

    // wide
    &.extras--panel--wide {
        @include media-breakpoint-up(md) {
            margin-left: auto;
            margin-right: auto;
            max-width: 856px;
        }
    }

    // bundles

    &.extras-bundle--panel {
        .bundle-items-layout {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
            .bundle-plus {
                color: $brand--red;
            }
        }
        @include media-breakpoint-up(md) {
            margin-left: auto;
            margin-right: auto;
            max-width: 856px;
            .bundle-items-layout {
                --item-max-width: 280px;
                flex-direction: row;
                justify-content: space-around;
                align-items: flex-start;
                .bundle-item {
                    width: calc(50% - 15px);
                }
                .bundle-plus {
                    width: 30px;
                }
            }
            &.three-items {
                max-width: none;
                .bundle-items-layout .bundle-item {
                    width: calc(33.33333% - 10px);
                }
            }
        }
    }
}
.extras-bundle--top-flash {
    display: flex;
    width: 100%;
    justify-content: center;
    border-bottom: 2px solid var(--panel-flash--bg-color);
    text-align: center;
    margin: 0 auto;
    max-width: 856px;
    strong {
        display: block;
        background-color: var(--panel-flash--bg-color);
        color: white;
        font-size: 0.8rem;
        text-transform: uppercase;
    }
    @include media-breakpoint-up(md) {
        &.three-items {
            max-width: none;
        }
    }
}

// booking form group panel

.booking-form-group-panel {
    background-color: rgba(white, 0.4);
    border: 1px solid rgba(white, 0.4);
}
