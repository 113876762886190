@keyframes video-loading-sprite {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-20px);
    }
}

.hero {
    position: relative;
    z-index: 1;
    height: calc(75vw + var(--header--height)); // 4:3 + header-height (image goes behind header)
    max-height: 450px;
    @include media-breakpoint-up(sm) {
        height: calc(.5625 * 100vw); // 16:9
        max-height: none;
    }

    @include media-breakpoint-up(lg) {
        height: calc(.5 * 100vw); // 2:1
    }

    @include media-breakpoint-up(xl) {
        height: calc(.33333 * 100vw); // 3:1
    }
    .site-layout & {
        margin-top: calc(-1 * var(--header--height));
    }
    // set a min height for small, landscape screens
    @media (max-width: map-get($grid-breakpoints, lg)) and (min-aspect-ratio: 4/3) {
        min-height: $hero--landscape--min-height;
    }

    // hero layout using flex to stretch images and content to fit vertically
    .hero--layout {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: $color--darkest-grey;
        .hero--img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            overflow: hidden;
            img {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: calc(100% + 1px) !important;
                object-fit: cover;
                font-family: 'object-fit: cover';
            }
        }
        .hero--content {
            display: block;
            position: relative;
            z-index: 2;
            width: 100%;
            pointer-events: none;
            .apprentice-flash-container {
                display: flex;
                @include media-breakpoint-down(sm) {
                    justify-content: center;
                }
                .apprentice-flash {
                    display: flex;
                    justify-content: center;
                    align-items: center;  
                    background: $brand--red;
                    width: 140px;
                    height: 140px;
                    position: relative;
                    text-align: center;
                    transform: rotate(-15deg);
                    // transform: rotate(-60deg);
                    border-radius: 500px;
                    @include media-breakpoint-down(sm) {
                        width: 130px;
                        height: 130px;
                        // transform: rotate(-45deg);
                        transform: rotate(0deg);
                    }
                    // &::before,
                    // &::after {
                    //   width: 100px;
                    //   height: 100px;
                    //   content: '';
                    //   position: absolute;
                    //   top: 0;
                    //   left: 0;
                    //   background: $brand--red;
                    //   border-radius: 5px;
                    //   @include media-breakpoint-down(sm) {
                    //     font-size: 14px;
                    //     width: 90px;
                    //     height: 90px;
                    // }
                    // }
                
                    // &::before {
                    //   transform: rotate(30deg);
                    // }
                
                    // &::after {
                    //   transform: rotate(60deg);
                    // }
                    h6 {
                        // position: relative;
                        // z-index: 3;
                        text-align: center;
                        // transform: rotate(45deg);
                        // @include media-breakpoint-down(sm) {
                        //     font-size: 15px;
                        // }
                    }
                }
            }
            .row > div {
                pointer-events: all;
            }
        }
    }

    // shallow variation
    &.hero--shallow {
        height: calc(50vw + var(--header--height)); // 2:1 + header-height (image goes behind header)
        @include media-breakpoint-up(sm) {
            height: calc(50vw); // 2:1
        }
        @include media-breakpoint-up(lg) {
            height: calc(33.33333vw); // 3:1
        }
        @include media-breakpoint-up(xl) {
            height: calc(25vw); // 4:1
        }
    }

    // use '.full-height' to fill the available viewport space

    &.full-height {
        min-height: $hero--mobile--min-height;
        height: calc(100vh - var(--top-utility-bar--height));
        max-height: none;
        @include media-breakpoint-up(sm) {
            min-height: $hero--desktop--min-height;
        }
    }

    // hero carousel

    .carousel, .carousel-inner, .carousel-item {
        height: 100%;
    }
}


// Hero video stuff

.hero .hero--layout .hero--video {
    --hero-video-tint: transparent;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &.tint-10 {
        --hero-video-tint: rgba(0,0,0,0.1);
    }
    &.tint-20 {
        --hero-video-tint: rgba(0,0,0,0.2);
    }
    &.tint-30 {
        --hero-video-tint: rgba(0,0,0,0.3);
    }
    &.tint-40 {
        --hero-video-tint: rgba(0,0,0,0.4);
    }
    &.tint-50 {
        --hero-video-tint: rgba(0,0,0,0.5);
    }
    &.tint-60 {
        --hero-video-tint: rgba(0,0,0,0.6);
    }
    &.tint-70 {
        --hero-video-tint: rgba(0,0,0,0.7);
    }
    &.tint-80 {
        --hero-video-tint: rgba(0,0,0,0.8);
    }
    &.tint-90 {
        --hero-video-tint: rgba(0,0,0,0.9);
    }
    .hero--video-inner { // should remain as 16:9 ratio container and resize to emulate object-fit: cover
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        --transition: opacity 1s linear;
        .hero--poster-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            opacity: 1;
            transition: var(--transition);
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0;
                transition: opacity 500ms linear;
                &.loaded {
                    opacity: 1;
                }
            }
        }
        iframe {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: var(--transition);
        }
        &.reveal {
            .hero--poster-image {
                opacity: 0;
            }
            iframe {
                opacity: 1;
            }
        }
        &.has-poster {
            --transition: none;
            iframe {
                opacity: 1;
            }
            &.crossfade-poster {
                --transition: opacity 1s linear;
            }
        }
        .hero--video-loading-sprite {
            position: absolute;
            z-index: 4;
            top: 15px;
            left: 15px;
            width: 60px;
            height: 3px;
            overflow: hidden;
            opacity: 1;
            will-change: opacity;
            &:before {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: calc(100% + 30px);
                height: 100%;
                background: linear-gradient(to right, rgba(black, 0.4) 0%, rgba(black, 0.4) 50%, white 50%, white 100%) repeat-x;
                background-size: 20px 100%;
                will-change: transform;
                animation: video-loading-sprite 1s infinite linear;
            }
            &.hidden {
                opacity: 0;
                transition: opacity 500ms linear;
            }
        }
        // tint?
        &:after {
            display: block;
            content: '';
            position: absolute;
            z-index: 4;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            background-color: var(--hero-video-tint);
        }
    }
    .hero--video--control {
        display: flex;
        flex-direction: row;
        gap: 4px;
        z-index: 5;
        top: calc(var(--header--height) + var(--promo-bar--height) + 15px);
        right: 15px;
        opacity: 0;
        transition: opacity 500ms linear;
        pointer-events: none;
        &.reveal {
            opacity: 1;
            pointer-events: all;
        }
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            appearance: none;
            width: 30px;
            height: 30px;
            font-size: 14px;
            line-height: 14px;
            margin: 0;
            padding: 0;
            border: 0;
            border-radius: 3px;
            background-color: rgba(black, .4);
            color: white;
            cursor: pointer;
            &:not(:disabled):focus, &:not(:disabled):hover {
                background-color: $primary;
                color: white;
            }
            .hero--video--control--play, .hero--video--control--pause, .hero--video--control--sound-on, .hero--video--control--sound-off {
                display: inline;
                &[aria-hidden="true"] {
                    display: none;
                }
            }
        }
    }
}

// Hero fixed-visual stuff - only if css clip-path supported
@supports(clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%)) {
    .hero--fixed-visual--wrapper {
        background-color: $color--darkest-grey;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        .hero {
            z-index: 1;
            .hero--layout {
                .hero--img, .hero--video {
                    position: fixed;
                    top: 0;
                    .hero--video--control {
                        position: absolute;
                        z-index: 2;
                        top: calc(var(--top-utility-bar--height) + var(--header--height) + var(--promo-bar--height) + 15px);
                    }
                }
            }
        }
        .hero--fixed-visual--extra-content {
            position: relative;
            z-index: 2;
        }
    }
}
