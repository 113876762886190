#header-wrapper {

    --logo--flyer--fill-color: #{$brand--red};
    --logo--text--fill-color: #{$brand--white};

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 10;
    height: 0;
    pointer-events: none;

    header {
        position: relative;
        height: calc(var(--top-utility-bar--height) + var(--header--height));
        transition: transform 300ms ease-in-out;
        will-change: transform;
        .header-hidden:not(.mobile-menu-active) & {
            transform: translate3d(0,calc(-1 * (var(--top-utility-bar--height) + var(--header--height) + var(--promo-bar--height))),0);
        }

        &:before {
            display: block;
            content: '';
            position: absolute;
            z-index: -1;
            top: var(--top-utility-bar--height);
            left: 0;
            width: 100%;
            height: var(--header--height);
            background-color: $brand--dark-blue;
            transition: background-color 300ms linear;
            will-change: background-color;
        }

        #skip-nav {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: var(--top-utility-bar--height);
            left: 0;
            z-index: 2;
            width: 100%;
            height: var(--header--height);
            background-color: white;
            font-size: 1rem;
            line-height: 1rem;
            text-decoration: underline;
            opacity: 0;
            pointer-events: none;
            transition: opacity 200ms linear;
            &:focus {
                opacity: 1;
                pointer-events: all;
            }
        }

        .header--utility-bar {
            --theme--link--color: #{$color--lighter-grey};
            --theme--link--color--hover: white;
            background-color: $color--darkest-grey;
            width: 100%;
            height: var(--top-utility-bar--height);
            font-family: $font-alt-heading;
            font-size: 14px;
            line-height: 14px;
            font-weight: 400;
            text-transform: uppercase;
            pointer-events: all;
            @include non-retina-font-smoothing-antialiased;
            .header--utility-bar--inner {
                height: var(--top-utility-bar--height);
                .btn {
                    display: inline-flex;
                    font-size: 14px;
                }
            }
        }

        .header--content {
            --theme--link--color: white;
            --theme--link--color--hover: white;
            display: flex;
            width: 100%;
            height: var(--header--height);
            justify-content: flex-end;
            align-items: center;
            gap: 15px;
            pointer-events: all;

            .header--logo {
                flex: 0 1 auto;
                width: 100%;
                max-width: 166px;
                margin-right: auto;
                a {
                    display: block;
                    width: 100%;
                    svg {
                        width: 100%;
                        .flyer {
                            color: var(--logo--flyer--fill-color);
                        }
                        .text {
                            color: var(--logo--text--fill-color);
                        }
                    }
                }
            }
            .header--cta {
                flex: 0 0 auto;
                .dropdown-menu {
                    margin-top: 15px !important;
                    border: 0;
                    border-radius: $btn-border-radius;
                    filter: drop-shadow(0 0 3px rgba(black, 0.3));
                    &:before {
                        display: block;
                        content: '';
                        position: absolute;
                        top: -15px;
                        right: 30px;
                        width: 0;
                        height: 0;
                        border-left: 15px solid transparent;
                        border-right: 15px solid transparent;
                        border-bottom: 15px solid white;
                    }
                    .dropdown-item {
                        display: flex;
                        flex-direction: row;
                        gap: 15px;
                        padding: 10px 30px;
                        color: $color--mid-grey;
                        & > svg {
                            font-size: 1.4rem;
                            color: $brand--red;
                        }
                        span {
                            display: block;
                            font-size: 1rem;
                            strong {
                                display: block;
                                font-family: $font-alt-heading;
                                font-weight: $headings-font-weight;
                                text-transform: uppercase;
                                line-height: 1;
                                color: $brand--red;
                            }
                            small {
                                display: block;
                                white-space: normal;
                                @include media-breakpoint-up(sm) {
                                    white-space: nowrap;
                                }
                            }
                        }
                        &:hover, &:focus, &.active {
                            background-color: $color--blue;
                            color: rgba(white, 0.6);
                            & > svg {
                                color: white;
                            }
                            span {
                                strong {
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
            .header--mobile-menu-toggle {
                flex: 0 0 auto;
                button {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    .bars {
                        display: block;
                        width: 21px;
                        height: 21px;
                        position: relative;
                        transition: transform 200ms ease-in-out;
                        will-change: transform;
                        i {
                            position: absolute;
                            top: 4px;
                            left: 0;
                            width: 21px;
                            height: 2px;
                            border-radius: 1px;
                            background-color: var(--theme--link--color);
                            transition: transform 200ms ease-in-out, width 200ms ease-in-out, opacity 200ms ease-in-out;
                            will-change: transform;
                            &:nth-child(1) {
                                transform-origin: left top;
                            }
                            &:nth-child(2) {
                                transform-origin: center center;
                                top: 10px;
                            }
                            &:nth-child(3) {
                                transform-origin: left bottom;
                                top: 16px;
                            }
                        }
                    }

                    &:hover, &:focus {
                        .bars i {
                            background-color: var(--theme--link--color--hover);
                        }
                    }
                
                    &[aria-expanded="true"] {
                        .bars {
                            transform: rotate(-90deg) translate3d(0,-0.5px,0);
                            i {
                                &:nth-child(1) {
                                    transform: translate3d(4px,-1px,0) rotateZ(45deg);
                                }
                                &:nth-child(2) {
                                    transform: scaleX(0);
                                }
                                &:nth-child(3) {
                                    transform: translate3d(4px,1px,0) rotateZ(-45deg);
                                }
                            }
                        }
                    }
                }
            }

        }

        @include media-breakpoint-up(lg) {
            .header--content {
                justify-content: space-between;
                align-items: center;
                .header--logo {
                    margin-right: 0;
                }
                .header--mobile-menu-toggle {
                    display: none;
                }
            }
        }
        @include media-breakpoint-up(xl) {
            .header--content {
                gap: 30px;
            }
        }
        @include media-breakpoint-up(xxl) {
            .header--content {
                gap: 60px;
            }
        }

    }

    #header--search-wrapper {
        --theme--link--color: #{$color--lighter-grey};
        --theme--link--color--hover: white;
        display: none;
        pointer-events: none;
        &[aria-hidden="false"] {
            display: flex;
            background-color: $brand--dark-blue;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 100%;
            height: calc(var(--top-utility-bar--height) + var(--header--height));
            text-align: center;
            opacity: 0;
            transition: opacity 200ms linear;
            &.search-form--active {
                opacity: 1;
                pointer-events: all;
            }
            #header--search-form {
                display: flex;
                margin: 0 auto;
                align-items: center;
                gap: 10px;
                height: calc(var(--top-utility-bar--height) + var(--header--height));
                padding: 0 15px;
                max-width: 400px;
                input[type="search"] {
                    flex: 1 1 auto;
                }
                button[type="submit"] {
                    flex: 0 0 auto;
                }
            }
            #header--search-close-btn {
                position: absolute;
                top: 0;
                right: 10px;
                display: flex;
                width: var(--btn-width);
                height: var(--top-utility-bar--height);
                justify-content: center;
                align-items: center;
                font-size: 18px;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        --btn-width: var(--header--utility-bar-height);
    }
    @include media-breakpoint-up(md) {
        background-color: $brand--dark-blue;

        .header--contents {
            padding-right: 15px;
        }

        #header--search-wrapper[aria-hidden="false"] {
            #header--search-close-btn {
                right: 25px;
            }
        }
        
    }

    // translucent header if hero exists

    body.with-hero & {
        header:before {
            background-color: rgba(black, 0.4);
            backdrop-filter: blur(10px);
        }
    }
    body.with-hero.page-scrolled & {
        header:before {
            background-color: $brand--dark-blue;
            transition-delay: 300ms;
        }
    }
    body.with-hero.mobile-menu-active &, .desktop-menu-active & {
        header:before {
            background-color: $brand--dark-blue;
        }
    }

}
